import { Container, Row, Col } from 'react-bootstrap'
import '../assets/css/About.css'
import Logo from '../assets/img/dmark.png'

function About() {
  return (
    <section className="Echron-about">
      <Container>
        <Row>
          <Col lg="12" xs="12">
            <h2>WELCOME TO DMARK LEATHER</h2>
            <p>
              Welcome to India’s most comprehensive promotional products and corporate gifting company based in New Delhi.
            </p>
            <p>Our priority is to provide you with the superior service beyond compare. Whether your gift is recognising achievements of your employees or showing your appreciation to your valued clients, we will facilitate the process of finding a perfect memorable gift of unsurpassed quality.
            </p>
            <p>At Dmark Leather, we ensure that your corporate product or premium business gift has the highest perceived value and maximum impact by using our state of the art in-house branding department utilising the most advanced branding processes in engraving, embossing and sublimation.
            </p>
            <p>Whether you are a start-up company or multinational corporation, you can easily find the perfect corporate merchandise which complements your marketing strategy. Dmark Leather can easily provide you with professional advice with regard to customising your chosen company or brand merchandise, ensuring you have considerable advantage over your competitors while enjoying the best value for your money.
            </p>
            <p>We are among the highly-respected & trusted vendors of big brands and have been recognized repeatedly by the them for Quality product, service and delivery.</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default About