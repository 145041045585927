import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../assets/css/Header.css";
import Logo from "../assets/img/logo.jpg";
import Sidebar from "./Sidebar";
import Nav from "./Nav";
import { setNav, selectNav, setAnimNav, selectAnimNav } from "../echron/navSlice"
import { useDispatch, useSelector } from 'react-redux'

function Header() {
  const dispatch = useDispatch();
  const isNav = useSelector(selectNav);

  const echronEvNav = () => {
    dispatch(setNav(true));
  };
  return (
    <header className="Echron-header">
      <div className="top">
        <Container>
          <Row className="align-items-center">
            <Col lg="3">
              <div className="logo">
                <NavLink to="/">
                  <img src={Logo} alt="" />
                </NavLink>
                <i onClick={echronEvNav.bind()} class="fa fa-bars"></i>
              </div>
            </Col>
            <Col lg="4">
              <form className="Echron-search-box">
                <input type="search" placeholder="Search Products..." />
                <button type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </Col>
            <Col lg="3">
              <a href="tel:9582197298" className="m-no"><i class="fa fa-phone"></i>+91-11-9582197298</a>
            </Col>
            <Col lg="2">
              <ul className="icon m-0 p-0">
                <li>
                  <i className="fa fa-facebook"></i>
                </li>
                <li>
                  <i className="fa fa-youtube-play"></i>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ------Nav Start------- */
        /* ------Nav Start------- */
        /* ------Nav Start------- */}
      <Nav />
      {isNav && <Sidebar />}
      {/* ------Nav End------- */}
      {/* ------Nav End------- */}
    </header>
  );
}
export default Header;
