import { NavLink } from "react-router-dom";
import { useGetNavLinkQuery } from "../echron/clientapi";
function FooterCol3() {
  const navRes = useGetNavLinkQuery();
  if(navRes.isLoading) return <div>Loading...</div>
  if(navRes.isError) return <h1>An Error occured {navRes.error.error}</h1>
  if(navRes.isSuccess) {
    return (
      <ul>
        <h3>Contact</h3>
        {
          navRes.data.header.map((nav) => 
            <li>
              <NavLink to={nav.url}>&gt;{nav.name}</NavLink>
            </li>
          )
        }
        <li>
          <i class="fa fa-map-marker"></i>&nbsp; WZ-121/3, Ground Floor, TodaPur, New Delhi-110012
        </li>
        <li>
          <i class="fa fa-phone-square"></i>&nbsp; Mobile: <a href="tel:9582197298">+91-9582-197-298</a>
        </li>
        <li>
          <i class="fa fa-envelope-o"></i>&nbsp; Email: <a href="mailto:dmarkleather@gmail.com">dmarkleather@gmail.com</a>
        </li>
      </ul>
    );
  }
}
export default FooterCol3;
