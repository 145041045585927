import { useGetCatsQuery } from '../echron/clientapi'
import { NavLink } from "react-router-dom"
function FooterCol2() {
  const catsRes = useGetCatsQuery()
  if(catsRes.isLoading) return <div>Loading...</div>
  if(catsRes.isError) return <h1>An Error occured {catsRes.error.error}</h1>
  if(catsRes.isSuccess) {
    return(
      <ul>
        <h3>Product</h3>
        {
          catsRes.data.category.map((post)=>
            <li><NavLink key={post.id} to={post.url}>&gt; {post.name}</NavLink></li>
          )
        }
      </ul>
    )
  }
}
export default FooterCol2