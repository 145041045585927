import { Container, Row, Col, Button } from 'react-bootstrap'
import '../assets/css/Contact.css'
import { echronEvInput, useSubmitForm, selectFormResp } from "../echron/formSlice"
import { useDispatch, useSelector } from 'react-redux'
import Warning from "./Warning";

function Contact() {
    const dispatch = useDispatch();
    //post api
    const submitForm = useSubmitForm();
    const formResps = useSelector(selectFormResp);
    const echronEvSubmit = () => {
        if(!formResps.load) {
        submitForm();
        }
    }

    //warning
    const errorBody =
    <div className="alert alert-danger"> 
        <ul>
        {
            formResps.errorList.map((list, i)=>
            {
                return <li key={i}>{list}</li>
            }
            )
        }
        </ul>
    </div>
    const errorHead = <h5 className="text-danger">Error Alert</h5>
  return (
    <section className="Echron-contact">
        {/* <div className='ConBan'>
            <img src='https://b2demo.in/dmark/contact/con_ban.jpg'alt='' />
        </div> */}
        <Container>
            <Row>
                <Col lg="6" >
                <div className='LeftText'>
                    <div className='Heading'>
                        <h1>FIND US QUICKLY</h1>
                        <h3>Trusted by the peoples</h3>
                        <p>We would be happy to hear from you, Please fill in the form below or mail us Your requirments.</p>
                    </div>
                    <div className='box'>
                        <h4><i class="fa-phone-volume"></i> Mobile No.</h4>
                        <p>+91-9582-197-298</p>
                    </div>
                    <div className='box'>
                        <h4>Email Address</h4>
                        <p>dmarkleather@gmail.com</p>
                    </div>
                    <div className='box'>
                        <h4>Address</h4>
                        <p>WZ-121/3, Fround Floor, TodaPur,<br/>New Delhi-110012</p>
                    </div>
                </div>
                </Col>
                <Col lg="6">
                    <div className='SideForm'>
                        <div className='Heading'>
                            <h1>INQUIRY</h1>
                            <p>Please fill in the form below or mail us your requirments</p>
                        </div>
                        <div class="FormField">
                            <div class="FormField-Inner">
                                <input class="input" type="text" placeholder="Full Name" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'name'}))}} />
                            </div>
                            <div class="FormField-Inner">
                                <input class="input" type="text" placeholder="Email Address" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'email'}))}} />
                            </div>
                            <div class="FormField-Inner">
                                <input class="input" type="text" placeholder="Contact No." onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'phone'}))}} />
                            </div>
                            <textarea class="input msg" placeholder="Message- ....................." onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'message'}))}}></textarea>
                            <Button onClick={()=>echronEvSubmit()}>{formResps.load ? 'Please Wait...' : 'Send your inquiry'}</Button>  
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        <div className='map'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.0516360225106!2d77.14720181558744!3d28.62821448241937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d02d7f7b1505f%3A0xb646e478ee2fd8f5!2sDmark+Leather!5e0!3m2!1sen!2sin!4v1542312448889"></iframe>
        </div>
        {formResps.error && <Warning body={errorBody} head={errorHead} />}
    </section>
  )
}
export default Contact