import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../assets/css/CatDetails.css";
import { useGetLocsQuery } from "../echron/clientapi";
function CatDetails(props) {
  const cat_id = props.catId;
  const catRes = useGetLocsQuery({ cat_id: cat_id });
  if (catRes.isLoading) return <div>Loading...</div>;
  if (catRes.isError) return <h1>An Error occured {catRes.error.error}</h1>;
  if (catRes.isSuccess) {
    let echronMap = catRes.data.post;
    return (
      <section className="Echron-cat-detail">
        <Container>
          {/* <Filter /> */}
          <div className="echron-card">
            <div className="Heading">
              <h1>{props.catName}</h1>
              {/* <p>
                Book Notes, paper blocks and sticky notes can all be found here
              </p> */}
            </div>
            <ul className="row">
              {echronMap.map((post) => (
                <li className="col-6 col-md-3">
                  <NavLink to={post.url}>
                    <figure>
                      <img src={post.image} alt="" />
                      <div className="echron-text">
                        <button className="button">{post.name}</button>
                        {/* <p>
                        NotePads in a range of sizes that come with a handy
                        ballpen attached
                      </p> */}
                      </div>
                    </figure>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </Container>
      </section>
    );
  }
}
export default CatDetails;
