import { Container, Row, Col } from "react-bootstrap";
import "../assets/css/Footerbt.css";
import FooterCol1 from "../component/FooterCol1";
import FooterCol2 from "./FooterCol2";
import FooterCol3 from "./FooterCol3";

function Footerbt() {
  return (
    <div className="EchronFooterbt">
      <Container>
        <Row>
          <Col lg="4" sm="12">
            <FooterCol1 />
          </Col>
          <Col lg="3">
            <FooterCol2 />
          </Col>
          <Col lg="5">
            <FooterCol3 />
          </Col>
        </Row>
        <Row>
          <div className="accredited">
            <Col lg="12">
              <p>
                Copyright &copy; {new Date().getFullYear()} Demark Leather | Design & Developed by&nbsp;
                <a target="_blank" href="https://echrontech.com/">
                  Echrontech
                </a>
              </p>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  );
}
export default Footerbt;
