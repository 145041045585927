import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const clientApi = createApi({
  reducerPath: 'clientApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://dmark.b2demo.in/api/'
  }),
  endpoints: (builder)=>({
    getCats: builder.query({
      query: (echron={}) => {
        let order = (echron.order)? echron.order : '';
        return {
          url: 'list/category?order='+order,
          method: 'GET'
        };
      }
    }),
    getCatsPosts: builder.query({
      query: (echron={}) => {
        let limit = (echron.limit)? echron.limit : '';
        let limitpost = (echron.limitpost)? echron.limitpost : '';
        return {
          url: 'post/cat?limit='+limit+'&limitpost='+limitpost,
          method: 'GET'
        };
      }
    }),
    getTagsPosts: builder.query({
      query: () => ({
        url: 'post/tag',
        method: 'GET'
      })
    }),
    getPageDet: builder.query({
      query: (echron) => ({
        url: 'page?url='+echron,
        method: 'GET'
      })
    }),
    getLocs: builder.query({
      query: (echron) => {
        const cat_id = (echron.cat_id)? echron.cat_id : 0;
        return {
          url: 'list/post?cat_id='+cat_id,
          method: 'GET'
        };
      }
    }),
    getNavLink: builder.query({
      query: () => ({
        url: 'nav',
        method: 'GET'
      })
    }),
    postEnqs: builder.mutation({
      query: (postNew) => {
        return {
          url: 'enquiry',
          method: 'POST',
          body: postNew
        };
      }
    }),
  })
})

export const { useGetCatsQuery, useGetCatsPostsQuery, useGetTagsPostsQuery, useGetPageDetQuery, useGetLocsQuery, useGetNavLinkQuery, usePostEnqsMutation } = clientApi