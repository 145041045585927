import { Container, Row } from 'react-bootstrap'
import { useGetCatsQuery } from '../echron/clientapi'
import Popular_card from '../component/Popular_card'

import '../assets/css/Popular.css'
function Popular() {
	const catsRes = useGetCatsQuery({order:'main'})
	if(catsRes.isLoading) return <div>Loading...</div>
	if(catsRes.isError) return <h1>An Error occured {catsRes.error.error}</h1>
	if(catsRes.isSuccess) {
		return (
			<section className="EchronPopular">
				<Container>
					<h2>Popular Ranges</h2>
					<Row className="py-3">
						{
							catsRes.data.category.map((post)=>
								<Popular_card key={post.id} text={post.name} img={post.image} url={post.url} class="child1" />
							)
						}
					</Row>
				</Container>

			</section>
		)
	}
}
export default Popular