//component
import Banner from '../component/Banner'
import Corporate from '../component/Corporate'
import Samplework from '../component/Samplework'
import Add from '../component/Add'
import Popular from '../component/Popular'
import Transform from '../component/Transform'
import Years from '../component/Years'
import Product from '../component/Product'

function Home() {
	return (
		<>
			<Banner />
			<Corporate />
			<Samplework />
			<Add />
			<Popular />
			<Transform />
			<Years />
			<Product />
		</>
	)
}
export default Home