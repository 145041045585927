import Carousel from 'react-bootstrap/Carousel'
import { Banner1, Banner2, Banner3, Banner4, Banner5, Banner6, Banner7, Banner8 } from './Image'
import '../assets/css/Banner.css'
function Banner() {
  const banner = [Banner1, Banner2, Banner3, Banner4, Banner5, Banner6, Banner7, Banner8]
  return (
    <>
      <div className="EchronHomebanner">
        <Carousel>
          {
            banner.map((img, i)=>
            <Carousel.Item key={i}>
              <div className="banner-image">
                <img src={img} alt="" />
              </div>
            </Carousel.Item>
            )
          }
        </Carousel>
      </div>
    </>
  )
}
export default Banner