import { Col } from "react-bootstrap"
function Years_card(props) {
    return (
        <Col lg="4">
            <div className="card">
                <figure>
                    <img src={props.img} alt="" />
                </figure>
                <p>{props.text}</p>
            </div>
        </Col>
    )
}
export default Years_card

