function FooterCol1() {
  return(
    <ul>
      <h3>About US</h3>
      <p>
        Welcome to India's most comprehensive promotional products and corporate
        gifting company based in New Delhi.
      </p>
      <p>
        Our priority is to provide you with the superior service beyond compare.
        Whether your gift is recognising achievements of your employees or showing
        your appreciation to your valued clients, we will facilitate the process of
        finding a perfect memorable gift of unsurpassed quality.
      </p>
  </ul>
  )
}
export default FooterCol1