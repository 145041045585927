import { useGetCatsQuery } from "../echron/clientapi";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function Nav() {
    const catsRes = useGetCatsQuery();
    if (catsRes.isLoading) return <div>Loading...</div>
    if (catsRes.isError) return <h1>An Error occured {catsRes.error.error}</h1>
    if (catsRes.isSuccess) {
        return (
            <>
                <nav className="Echron-nav">
                    <Container>
                        <Row>
                            <Col lg="12" sm="12" className="side-navigation">
                                <ul>
                                    {catsRes.data.category.map((post) => (
                                        <li>
                                            <NavLink key={post.id} to={post.url}>
                                                {post.name}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </nav>
            </>
        )
    }
}

export default Nav;