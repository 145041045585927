import Nav from "./Nav";
import { useDispatch } from "react-redux";
import { setNav } from "../echron/navSlice";

function Sidebar() {
    const dispatch = useDispatch();
    const echronEvNav = () => {
        dispatch(setNav(false));
    };
    return (
        <>
            <div className="echron-sidenavbar">
                <i onClick={echronEvNav.bind()} class='fa fa-arrow-right'></i>
                <Nav />
                <div className="contact">
                    <h4 className="h4">Need any help ?</h4>
                    <ul>
                        <li>
                            <i class="fa fa-phone"></i>
                            <a href="tel:9582197298">+91-9582-197-298</a>
                        </li>
                        <li>
                            <i class="fa fa-envelope"></i>
                            <a href="mailto:dmarkleather@gmail.com">dmarkleather@gmail.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar;