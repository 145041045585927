import { Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
function Popular_card(props) {
    return (
        <Col lg="4" xs="6" className="cards">
            <NavLink to={props.url}>
                <div className={`Card ${props.class}`}>
                    <img src={props.img} alt="" />
                    <h3>{props.text}</h3>
                </div>
            </NavLink>
        </Col>
    )
}
export default Popular_card