//router
import {BrowserRouter, Routes, Route} from 'react-router-dom'

//component
import Echrontech from './screen/Echrontech'
import Home from './screen/Home'
import Page from './screen/Page'


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Echrontech />}>
            <Route index element={<Home />} />
            <Route path="*" element={<Page />} />
          </Route>
          <Route path="404" element={<h1>404 Error</h1>} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
