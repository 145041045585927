import { Container, Row, Col } from "react-bootstrap";
import Fancybox from "./Fancybox";
import "../assets/css/ProDetails.css";
import React from "react";

function ProDetails(props) {
  return (
    <section className="echron-pro-detail">
      <h1>{props.proName}</h1>
      <Container>
        <Fancybox options={{
          Carousel: {
            infinite: false,
          },
        }}>
          <Row>
            {props.proGal.map((post) => (
              <Col lg="4" className="list" xs="6">
                <a data-fancybox="gallery" href={post.image}>
                  <img src={post.image} />
                </a>
              </Col>
            ))}
          </Row>
        </Fancybox>
      </Container>
    </section>
  );
}
export default ProDetails;
