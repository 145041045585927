import { Col, Row, Container } from "react-bootstrap"
import '../assets/css/Transform.css'
import t1 from '../assets/img/t.jpg'
function Transform() {
    return (
        <section className="Echron-Transform">
            <Container>
                <Row>
                    <Col lg="6">
                        <img className="trans-img" src={t1} alt="" />
                    </Col>
                    <Col lg="6 my-auto">
                        <h2>tranform gifting with dmark leather: your journey to remarkable gifts</h2>
                        <ul>
                            <li><i className="fa fa-long-arrow-right"></i> Discover Budget-friendly products tailored to your requirement all thanks to our extensive network of factory partnerships cultivated over more than two decade.</li>
                            <li><i className="fa fa-long-arrow-right"></i> Elevate your brand with our expert artwork and printing services creating eye-catchinbg placements taht leave a lasting impression.</li>
                            <li><i className="fa fa-long-arrow-right"></i> Enjoy hassle-free ordering as we take care of all the follow-ups to ensure timely and as good as new delivery of your items.</li>
                        </ul>
                        
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Transform

