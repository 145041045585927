import { Row, Container } from "react-bootstrap"
import Years_card from '../component/Years_card'
import '../assets/css/Years.css'
import y1 from '../assets/img/y1.png'
import y2 from '../assets/img/y2.png'
import y3 from '../assets/img/y3.png'
function Years() {
    return (
        <section className="Echron-Years">
            <Container>
                <Row>
                    <Years_card
                        img={y1}
                        text="25+ YEARS EXPERIENCE"
                    />
                    <Years_card
                        img={y2}
                        text="share your event Calender"
                    />
                    <Years_card
                        img={y3}
                        text="Easy and cost effective ordering we undertake third party duties also"
                    />
                </Row>
            </Container>
        </section>
    )
}
export default Years

