import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { clientApi } from "./clientapi"
import navSlice from "./navSlice"
import formSlice from "./formSlice"
export const store = configureStore({
  reducer: {
    [clientApi.reducerPath]: clientApi.reducer,
    nav: navSlice,
    form: formSlice,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(clientApi.middleware)
})

setupListeners(store.dispatch)