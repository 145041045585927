import { Container, Row, Col } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
import s1 from '../assets/img/s1.jpg'
import s2 from '../assets/img/s2.jpg'
import s3 from '../assets/img/s3.jpg'
import s4 from '../assets/img/s4.jpg'
import Slider from "react-slick";
import "../assets/css/SliderSlick.css";

import '../assets/css/Samplework.css'
function Samplework() {
    var saleem = {
        infinite: true,
      speed: 500,
      slidesToShow: 4,
      autoplay: true,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
      };
    return (
        <section className="Echron-Samplework">
            <Container>
                <h2>Sample Of Our Work</h2>
                <Slider {...saleem}>
                    <div className="sliderimg">
                        <img src={s1} alt="" />
                    </div>
                    <div className="sliderimg">
                        <img src={s2} alt="" />
                    </div>
                    <div className="sliderimg">
                        <img src={s3} alt="" />
                    </div>
                    <div className="sliderimg">
                        <img src={s4} alt="" />
                    </div>
                    <div className="sliderimg">
                        <img src={s1} alt="" />
                    </div>
                    <div className="sliderimg">
                        <img src={s2} alt="" />
                    </div>
                    <div className="sliderimg">
                        <img src={s3} alt="" />
                    </div>
                    <div className="sliderimg">
                        <img src={s4} alt="" />
                    </div>
                </Slider>
            </Container>
        </section>
    )

}
export default Samplework