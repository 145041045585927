import { Container, Row, Col } from 'react-bootstrap'
import addbanner from '../assets/img/Add.jpg'


import '../assets/css/Add.css'
function Add() {
    return (
        <section className="Echron-Add">
            <Container>
                <Row>
                    <Col lg="12">
                        <div className="promation-banner">
                            <img src={addbanner} alt="" />
                            <h3>Promational<br/> Products<br/><span>Owned By Many,<br/>Cherished By Everyone!</span></h3>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}
export default Add