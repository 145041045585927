import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"
import { useGetPageDetQuery } from "../echron/clientapi";
import CatDetails from "../component/CatDetails";
import ProDetails from "../component/ProDetails";
import About from "../component/About";
import Contact from "../component/Contact";
function Page() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const path = useLocation();
  const finalPath = path.pathname.slice(1);
  const pageRes = useGetPageDetQuery(finalPath);
  if(pageRes.isLoading) return <div>Loading...</div>
  if(pageRes.isError) return <h1>An Error occured {pageRes.error.error}</h1>
  if(pageRes.isSuccess) {
    if (pageRes.data.status === "404") {
      return window.location.href = '/404';
    }
    else {
      const page = pageRes.data.status.page;
      const data = pageRes.data.status.data;
      if(page === "post") {
        return (
          <ProDetails proGal={data.gallery} proName={data.name} />
        )
      }
      else if(page === "category") {
        return (
          <CatDetails catName={data.name} catId={data.id} />
        )
      }
      else if(page === "page") {
        if(data.id==1) {
          return (
            <About />
          )
        }
        else if(data.id==2) {
          return (
            <Contact />
          )
        }
        else {
          return (
            <h1>Dynamic page</h1>
          )
        }
      }
      else {
        return (
          <h1>Dynamic page | {page}</h1>
        )
      }
    }
  }
}
export default Page