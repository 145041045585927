import { Col, Row, Container } from "react-bootstrap"
import {NavLink} from 'react-router-dom'
import { useGetTagsPostsQuery } from '../echron/clientapi'
import '../assets/css/Product.css'
function Product() {
	const tagPostRes = useGetTagsPostsQuery()
	if(tagPostRes.isLoading) return <div>Loading...</div>
	if(tagPostRes.isError) return <h1>An Error occured {tagPostRes.error.error}</h1>
	if(tagPostRes.isSuccess) {
		return (
			<section className="Echron-Product">
				{
					tagPostRes.data.tag.map((tag)=>
						<div key={tag.id} className="list">
							<Container>
								<h2>{tag.name}</h2>
								<p>{tag.description}</p>
								<Row>
									{
										tag.post_list.post.map((post)=>
											<Col key={post.id} lg="3 cards" xs="6">
												<NavLink to={post.url}>
													<div className="card">
														<figure>
															<img src={post.image} alt=""/>
														</figure>
														<h3>{post.name}</h3>
													</div>
												</NavLink>
											</Col>
										)
									}
								</Row>
							</Container>
						</div>
					)
				}
			</section>
		)
	}
}
export default Product

