import { Container, Row, Col } from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import { useGetCatsPostsQuery } from '../echron/clientapi'
import '../assets/css/Footer.css'
function Footer() {
  const catPostRes = useGetCatsPostsQuery({limit: '4', limitpost: '4'})
  if(catPostRes.isLoading) return <div>Loading...</div>
	if(catPostRes.isError) return <h1>An Error occured {catPostRes.error.error}</h1>
  if(catPostRes.isSuccess) {
    return (
      <footer className="Echron-footer">
        <Container>
          <h2>Quicks Links</h2>
          <Row>
            {
              catPostRes.data.category.map((cat)=>
                <Col lg="3" xs="6">
                  <ul>
                    <h3>{cat.name}</h3>
                    {
                      cat.post_list.post.map((pt)=>
                        <li><NavLink to={pt.url}>&gt; {pt.name}</NavLink></li>
                      )
                    }
                  </ul>
                </Col>
              )
            }
          </Row>
        </Container>
      </footer>
    )
  }
}
export default Footer