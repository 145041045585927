import { Container, Row, Col, } from 'react-bootstrap'
import '../assets/css/Corporate.css'
import C1 from '../assets/img/c1.jpg'
import C2 from '../assets/img/c2.jpg'
import C3 from '../assets/img/c3.jpg'
import C4 from '../assets/img/c4.jpg'


function Corporate() {
    return (
        <section className="Echron-Corporate">
            <Container>
                <h2>Corporate Gifts and Promotional Items</h2>
                <div className='content'>
                    <Row>
                        <Col lg="6"className='mt-2'>
                            <Row>
                                <Col xs="8" lg="8">
                                    <div className='item'>
                                        <img src={C1} alt="" />
                                        <h3>Festival Gifts</h3>
                                    </div>
                                </Col>
                                <Col xs="4" lg="4">
                                    <div className='item'>
                                        <div className='text'>
                                            Festival Gifts Ideas! Celebrate in style and send gifts to your clients and staff
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="6">
                            <Row>
                                <Col lg="12" className='mt-2'>
                                    <div className='item'>
                                        <img src={C2} alt="" />
                                        <h4>Working<br />From Home</h4>
                                    </div>
                                </Col>
                                <Col lg="6" xs="6" className='mt-2'>
                                    <div className='item'>
                                        <img src={C3} alt="" />
                                        <h5>Welcome Kit</h5>
                                    </div>
                                </Col>
                                <Col lg="6" xs="6" className='mt-2'>
                                    <div className='item'>
                                        <img src={C4} alt="" />
                                        <h6>Corporate Giveaway</h6>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="12">
                            <div className='item-2'>
                                <h1>Elevate</h1>
                                <div className='col-12 col-lg-4 text-2'>
                                    Your Appreciation With Our Corporate<br />Gifts
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>

        </section>
    )
}
export default Corporate